import {createAction, props} from "@ngrx/store";
import {AuthDto} from "../../auth/data/auth.dto";

export const loadAccount = createAction("[Settings/API] Load Account");
export const loadAccountSuccess = createAction("[Settings] Load Account Success", props<{account: AuthDto}>());

export const changeAccountEmail = createAction("[EmailSettingsCard/API] Change Email", props<{email: string}>());
export const changeAccountEmailWaitingForApproval = createAction(
    "[EmailSettingsCard] Change Email Waiting For Approval",
    props<{email: string}>(),
);

export const resetAccount = createAction("[Settings] Reset Account");
export const resetAccountSuccess = createAction("[Settings] Reset Account Success");

export const deleteAccount = createAction("[Settings] Delete Account", props<{confirmation: boolean}>());
export const deleteAccountSuccess = createAction("[Settings] Delete Account Success");
