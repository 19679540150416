import {Injectable} from "@angular/core";
import {TranslationKey} from "@bb-core/entity";
import {InteractionService, MessagingService, NavigationService, TranslationService} from "@bb-core/service";
import {Actions, createEffect, ofType, OnInitEffects} from "@ngrx/effects";
import {Action} from "@ngrx/store";
import {Observable, of} from "rxjs";
import {exhaustMap, filter, map, switchMap, tap} from "rxjs/operators";
import {AccountService} from "../../account";
import {AccountResetSettingsDto} from "../../account/data/account-reset-settings.dto";
import {AccountRepository} from "../../account/data/account.repository";
import {AccountSyncSettings} from "../../account/model/account-sync-settings.model";
import {SyncMode} from "../../account/model/sync-mode.enum";
import {AuthRepository} from "../../auth/data/auth.repository";

import {
	changeAccountEmail,
	changeAccountEmailWaitingForApproval,
	deleteAccount,
	deleteAccountSuccess,
	loadAccount,
	loadAccountSuccess,
	resetAccount,
	resetAccountSuccess,
} from "./account.actions";
import {RefinerService} from "@app/app/shared/service/refiner.service";

@Injectable()
export class AccountEffects implements OnInitEffects {
    readonly loadAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAccount),
            switchMap(() => this.authRepository.getAuth()),
            map((account) => loadAccountSuccess({account})),
        ),
    );

    readonly changeEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeAccountEmail),
            switchMap(({email}) => this.accountRepository.patchProfile({MailAddress: email}).pipe(map(() => email))),
            tap(() => this.messageService.showSnackBar("flash.please_confirm_email")),
            map((email) => changeAccountEmailWaitingForApproval({email})),
        ),
    );

    readonly resetAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetAccount),
            switchMap(() => this.openResetAccountSettingsDialog()),
            switchMap((settings) => (settings.DeleteOrders ? this.openSyncSettingsDialog(settings) : of(settings))),
            switchMap((settings) =>
                this.openConfirmDialog(
                    "title.confirm_delete",
                    "text.account.html_confirm_delete_selected_data",
                    "button.delete",
                    "button.cancel",
                ).pipe(
                    filter((confirm) => !!confirm),
                    map(() => settings),
                ),
            ),
            switchMap((settings) => this.accountRepository.resetAccount(settings)),
            map(() => resetAccountSuccess()),
        ),
    );

	readonly resetAccountSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(resetAccountSuccess),
			tap(() => this.messageService.showSnackBar("flash.account_reset_successful")),	
		),
		{dispatch: false},
	);

    readonly deleteAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteAccount),
            filter(({confirmation}) => !!confirmation),
            exhaustMap(() => this.refinerService.showChurnForm()),
            switchMap(() =>
                this.openConfirmDialog(
                    "title.confirm_delete_billbee_account",
                    "text.html_confirm_delete_billbee_account",
                    "button.delete",
                    "button.cancel",
                ).pipe(filter((confirm) => !!confirm)),
            ),
            switchMap(() => this.accountRepository.deleteAccount(true)),
            map(() => deleteAccountSuccess()),
        ),
    );

	readonly deleteAccountSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteAccountSuccess),
			tap(() => {
                const hintText = this.translator.translate("flash.account_deleted");
                this.navigator.navigateToBillbeeAccountDeleted(hintText);
            }),	
		),
		{dispatch: false},
	);

    constructor(
        private readonly actions$: Actions,
        private readonly accountRepository: AccountRepository,
		private readonly authRepository: AuthRepository,
		private readonly accountService: AccountService,
        private readonly messageService: MessagingService,
        private readonly interactionService: InteractionService,
        private readonly translator: TranslationService,
		private readonly navigator: NavigationService,
        private readonly refinerService: RefinerService,
    ) {}

    ngrxOnInitEffects(): Action {
        return loadAccount();
    }

    private openResetAccountSettingsDialog(): Observable<AccountResetSettingsDto> {
        return this.accountService.openEnterAccountResetSettingsDialog()
            .pipe(
				filter((settings): settings is AccountResetSettingsDto => !!settings),
				filter((settings) => !Object.values(settings).every((value) => !value)),
			);
    }

    private openSyncSettingsDialog(settings: AccountResetSettingsDto): Observable<AccountResetSettingsDto> {
        return this.accountService.openEnterAccountSyncSettingsDialog()
            .pipe(
                filter((syncSettings): syncSettings is AccountSyncSettings => !!syncSettings),
                map((syncSettings) => {
                    settings.DownloadOrders = syncSettings.SyncMode !== SyncMode.never;
                    if (settings.DownloadOrders) {
                        settings.NewLastSyncDate = syncSettings.StartDate;
                        settings.NewLastSyncDate?.setHours(0, 0, 0, 0);
                    }
                    return settings;
                }),
            );
    }

    private openConfirmDialog(
        title: TranslationKey,
        message: TranslationKey,
        yesText: TranslationKey = "button.yes",
        noText: TranslationKey = "button.no",
    ): Observable<boolean> {
        const t = this.translator.translate(title);
        const m = this.translator.translate(message);
        return this.interactionService.confirm$(t, m, {
            yesText,
            noText,
            isDangerousToProceed: true,
        });
    }
}
