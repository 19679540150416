import {createReducer, on} from "@ngrx/store";
import {deleteAccountSuccess, loadAccountSuccess} from "./account.actions";

export interface AccountState {
	Id: string;
	ProfileId: number;
    UserName: string;
	FullName: string;
    IsNewCustomer: boolean;
	MailAddress: string;
	IsMasterUser: boolean;
	Roles: string[];
}

export const initialAccountState: AccountState = {
	Id: "",
	ProfileId: 0,
    UserName: "",
	FullName: "",
	IsNewCustomer: false,
	MailAddress: "",
	IsMasterUser: false,
	Roles: [],
};

export const accountReducer = createReducer(
    initialAccountState,

    on(loadAccountSuccess, (state, {account}) => ({
		...state,
		Id: account.userId,
		ProfileId: account.profileId,
		UserName: account.userName,
		FullName: account.fullName,
		IsNewCustomer: account.isNewCustomer,
		MailAddress: account.email,
		IsMasterUser: account.isMasterUser,
		Roles: account.roles,
	})),

    on(deleteAccountSuccess, () => Object.assign({}, initialAccountState)),
);
