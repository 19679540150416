import {ChangeDetectorRef, Component, ElementRef, Injector, NgZone, OnInit, ViewChild} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import {UpgradeModule} from "@angular/upgrade/static";
import {SignalrHandlerService} from "@app/app/common/service/signalr-handler.service";
import { filter } from "rxjs/operators";
import { JavascriptSnippetHelperService } from "@app/app/common/service";
import {LegacyBridgeService} from "@app/app/common/service";

@Component({
    selector: "bb-app",
    template: `
        <div #outer>
            <div ui-view></div>
        </div>
        <bb-navigation *ngIf="isInitialized" fxFlexFill>
            <div #realUiView></div>
            <ng-container *ngIf="canNavigate">
              <router-outlet></router-outlet>
            </ng-container>
        </bb-navigation>
    `,
})
export class AppComponent implements OnInit {

    public isInitialized: boolean = false;
    public pageLoadJsScriptsLoaded: boolean = false;

    @ViewChild("outer", {static: false})
    public outer: ElementRef<HTMLDivElement>;

    @ViewChild("realUiView", {static: false})
    public realUiView: ElementRef<HTMLDivElement>;

    constructor(private upgrade: UpgradeModule,
                private readonly cdr: ChangeDetectorRef,
                private readonly injector: Injector,
                private readonly javascriptSnippetHelperService: JavascriptSnippetHelperService,
    ) {
    }

    public ngOnInit(): void {
        console.log("hybrid app bootstrapped");
    }

    public canNavigate: boolean = false;
    public bootstrapped(): void{
        this.isInitialized = true;
        this.cdr.detectChanges();

        this.realUiView.nativeElement.parentElement.replaceChild(this.outer.nativeElement, this.realUiView.nativeElement);
        this.canNavigate = true;
        // Init the SignalR Handler
        this.injector.get(SignalrHandlerService);

        const zone = this.injector.get(NgZone);

        const router = this.injector.get(Router);
        let legacyBridge = null;
        let lastUrl;
        router.events.pipe(
            filter(e => e instanceof NavigationEnd),
        ).subscribe(async (e: NavigationEnd) => {
            if (legacyBridge == null) {
                legacyBridge = this.injector.get(LegacyBridgeService);
            }
            const currentUrl = e.url;
            if (lastUrl !== currentUrl) {
                lastUrl = currentUrl;

                zone.runOutsideAngular(async () => {
                    await this.javascriptSnippetHelperService.loadAndExecuteJsSnippets(currentUrl, !this.pageLoadJsScriptsLoaded);
                    this.pageLoadJsScriptsLoaded = true;
                });
            }
        });
    }
}
